<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="'notice-add-modal-'"
      ref="modal"
      :title="isLaboratory ? ' Dodaj Notatkę (LABORATORIUM)' : 'Dodaj Notatkę'"
      size="lg"
      scrollable
      @show="initModal"
      @hide="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <b-form novalidate>
        <b-form-group
          :label="'Treść nowej notatki dla: ' + client.name"
          label-for="content"
          invalid-feedback="To pole jest wymagane"
          :state="validateField('content')"
        >
          <b-form-textarea
            v-model="$v.form.content.$model"
            :class="{ 'custom-textarea': true }"
            size="lg"
          >
          </b-form-textarea>
        </b-form-group>
      </b-form>
      
      <div class="mb-4 mt-4" v-if="this.loading">
        ŁADOWANIE DANYCH....
      </div>

      <div>
        <div class="timeline timeline-3">
          <div class="timeline-items">
            <div
              class="timeline-item"
              v-for="(note, i) in notices"
              :key="i"
            >
              <div
                class="timeline-media"
                :class="i === 0 ? 'bg-primary' : 'bg-white'"
              >
                <span
                  class="font-size-lg"
                  :class="i === 0 ? 'text-white' : 'text-primary'"
                >
                  {{ getInitials(note.user) }}
                </span>
              </div>
              <div class="timeline-content">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <div class="mr-2">
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-weight-bold"
                    >
                      {{ note.user.firstName + " " + note.user.lastName }}
                    </a>
                    <span class="text-muted ml-2">
                      {{ getNoteCreatedAt(note.createdAtObj.date) }}
                    </span>
                    <span
                      v-if="i === 0"
                      class="label label-light-success font-weight-bolder label-inline ml-2"
                      >najnowsza notatka
                    </span>

                    <span class="label label-default font-weight-bolder label-inline ml-2"
                    >
                      <template v-if="note.lab === true">
                        notatka laboratoryjna
                      </template>
                      <template v-else>
                        notatka standardowa
                      </template>
                    </span>
                  </div>
                  <div>
                    <b-dropdown
                      :id="'dropdown-note-' + i"
                      no-caret
                      :lazy="true"
                      variant="link"
                      toggle-class="text-decoration-none"
                    >
                      <template #button-content>
                        <span
                          class="ki ki-more-hor font-size-lg text-primary"
                        ></span>
                      </template>
                      <b-dropdown-item href="#" @click="deleteNotice(note.id)"
                        >Usun notatkę</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
                <p class="p-0">
                  {{ note.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { baseURL } from "@/core/services/api.service.js";
import { required } from "vuelidate/lib/validators";
import {
  NOTICE_ADD,
  NOTICE_SOFT_DELETE,
  NOTICE_LIST,
  CLEAR_NOTICES
} from "@/core/services/store/notice.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  name: "notice-add-modal",
  props: {
    client: Object,
    type: {
      type: String
    }
  },
  data() {
    return {
      form: {
        clientId: null,
        formState: null,
        content: "",
        isLab: false
      },
      collapseNote: [],
      loading: true
    };
  },
  validations: {
    form: {
      content: {
        required
      }
    }
  },
  methods: {
    deleteNotice(noteId) {
      Swal.fire({
        title: "Usuwanie notatki",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.handleDelete(noteId);
          Swal.fire("Gotowe!", "Usunięto.", "success");
        }
      });
    },
    addNotice() {
      Swal.fire({
        title: "Dodawanie notatki?",
        text: "Na pewno chcesz to zrobić?",
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.handleSubmit();
          Swal.fire("Gotowe!", "Dodano.", "success");
        }
      });
    },
    isNoteVisible(id) {
      return this.collapseNote.includes(id);
    },
    handleCollapseNote(id) {
      const exists = _.includes(this.collapseNote, id);
      if (!exists) {
        this.collapseNote.push(id);
      } else {
        const index = _.findIndex(this.collapseNote, id);
        let collapseSO = [...this.collapseNote];
        collapseSO.splice(index, 1);
        this.collapseNote = collapseSO;
      }
    },
    resetForm() {
      this.form.formState = null;
      this.$store.commit(CLEAR_NOTICES)
      this.loading = true;
      this.$emit('closedNotice')
    },
    handleDelete(id) {
      this.$store.dispatch(NOTICE_SOFT_DELETE, { id: id }).then(() => {});
      this.$nextTick(() => {
        Swal.fire("Gotowe!", "Usuwanie udane.", "success");
      });
    },
    handleSubmit() {
      this.$store.dispatch(NOTICE_ADD, this.form).then(() => {});
      this.$nextTick(() => {
        this.$bvModal.hide("notice-add-modal-" );
      });
    },
    handleModalOk(event) {
      event.preventDefault();
      this.addNotice();
    },
    validateField(field) {
      const { $dirty, $error } = this.$v.form[field];
      return $dirty ? !$error : null;
    },
    validateForm() {
      this.$v.form.$touch();
      this.form.formState = !this.$v.form.$invalid;
    },
    initModal() {
      // this.$store.dispatch(NOTICE_FOR_CLIENT_LIST, {
      //   id: this.client.id
      // });
      // if (this.notices.length === 0) {
      //   this.$store.dispatch(NOTICE_LIST);
      // }
      this.form.clientId = this.client.id;
      this.form.content = null;
      this.form.isLab = this.isLaboratory;
    },
    getNoteCreatedAt(date) {
      let m = new moment(date);
      return m.format("DD.MM.YYYY HH:mm:ss");
    },
    getInitials(user) {
      return (
        _.upperCase(user.firstName.charAt()) +
        _.upperCase(user.lastName.charAt())
      );
    },
    truncatedNoteContent(content, length) {
      return _.truncate(content, {
        length: length,
        omission: " [...]"
      });
    }
  },
  mounted() {
    this.initModal();
  },
  computed: {
    ...mapGetters(["notices"]),
    getBaseURL() {
      return baseURL;
    },
    // notesToShow() {
    //   return _.filter(this.notices, n => {
    //     return n.client.id == this.form.clientId && n.lab == this.isLaboratory;
    //   });
    // },
    isLaboratory() {
      return this.type === "laboratory";
    }
  },
  watch: {
    client() {
      if ( this.client.id ) {
        this.$store.dispatch(NOTICE_LIST, {id: this.client.id});
        this.form.clientId = this.client.id;
      }
    },
    notices(newVal) {
      if ( null !== newVal )
        this.loading = false;

      this.$forceUpdate();
    }
  }
};
</script>
